import React from 'react'
import './hero.scss'
import bg from '../../images/bg.jpg'
import Quotation from '../quotation/quotation'
import Header from '../header/header'

const block = 'hero'
const Hero = () => {
  return (
    <div className={block}>
      <Header />
      <div className={`container ${block}__container`}>
        <div className={`${block}__text`}>
          <h1 className={`${block}__highlight`}>
            A JMS é parceira de quem procura uma casa de câmbio ágil, eficiente
            e sem burocracias em Arujá e região.
          </h1>
          <h2 className={`${block}__description`}>
            Estamos há 25 minutos do GRU Airport - Aeroporto Internacional de
            São Paulo
          </h2>
        </div>
        {/* <Quotation className={`${block}__quotation`} /> */}
        <div className={`${block}__widget`}>
          <iframe
            className={`${block}__widget-iframe`}
            src="https://app.trevisobank.com.br/#/80/JM.S%20SERVICOS"
            scrolling="no"
            target="PocketStore"
          ></iframe>
        </div>
      </div>
      {/* style="border: none #000000; border-radius: 30px;" */}
    </div>
  )
}

export default Hero
