import React from 'react'
import './page-header.scss'
import bg from '../../images/bg.jpg'

const block = 'page-header'
const PageHeader = ({ headline, description }) => {
  return (
    <div className={block}>
      <div className={`container ${block}__container`}>
        <div className={`${block}__text`}>
          <h1 className={`${block}__hero-highlight`}>{headline}</h1>
          <div className={`${block}__description`}>{description}</div>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
