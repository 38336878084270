import * as React from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Header from '../../components/header/header'
import WithHeaderLayour from '../../layouts/with-header'
import Footer from '../../components/footer/footer'
import Hero from '../../components/hero/hero'
import Quotation from '../../components/quotation/quotation'
import PageHeader from '../../components/page-header/page-header'
import SeloAbracam from '../../components/selo-abracam/selo-abracam'

const IndexPage = ({ data, location }) => {
  const nomeDaEmpresa = data.contentfulGeral.nomeDaEmpresa
  const telefone = data.contentfulGeral.telefone

  return (
    <WithHeaderLayour>
      <Header currentPath={location?.pathname} />
      <PageHeader
        headline={`Internacional`}
        description="O chip internacional é a opção favorita de quem não abre mão da liberdade fundamental de se conectar com quem quiser ou precisar, esteja onde estiver."
      />
      <div className="container" style={{ padding: '70px 0' }}>
        <h2>Transferências internacionais sem complicação para você</h2>
        <div>
          A JMS oferece uma estrutura preparada para que você realize
          transferências internacionais de qualquer valor.
        </div>
        <h2>Segurança e agilidade</h2>
        <div>
          Nossas operações são liquidadas por meio de bancos nacionais e
          internacionais sólidos e tradicionais no mercado, garantindo uma
          operação rápida e segura.
        </div>
        <h2>Atendimento</h2>
        <div>
          Nossos operadores oferecem uma experiência personalizada para cada
          cliente, zelando pela transparência e rapidez no processo. Estamos
          presentes em todo o território nacional por telefone, WhatsApp ou
          e-mail.
        </div>
        <h2>Comodidade</h2>
        <div>
          Além da agilidade na transação (o dinheiro chega ao destinatário em
          até 48 horas úteis), não há valor mínimo para envio ou recebimento de
          quantias do exterior.
        </div>
        <h2>Expertise</h2>
        <div>
          As mesas de operações são compostas por profissionais qualificados com
          grande experiência no mercado de câmbio e rígida conduta ética, que
          sempre buscam oferecer as melhores taxas e condições através de
          processos transparentes que atendam às legislações vigentes.
        </div>
      </div>
      <SeloAbracam />
      <Footer />
    </WithHeaderLayour>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query InternacionalQuery {
    contentfulGeral {
      nomeDaEmpresa
      telefone
    }
  }
`
